<template>
  <div class="head__icons">
    <div class="head__icons-favorite" v-if="isFavorite">
      <heart class="head__icons-icon" />
    </div>
    <hcc-dropdown custom>
      <template #button>
        <hcc-button-icon>
          <div class="head__icons-container">
            <palette-swatch-icon class="head__icons-icon" />
            <span class="head__icons-label"
              >{{ $t("configuration.tabs.categories") }}
            </span>
          </div>
        </hcc-button-icon>
      </template>
      <hcc-dropdown-item v-if="groups.length === 0" unclickable>
        <div class="head__labels-empty">
          {{ $t("configuration.categories.empty-categories") }}
        </div>
      </hcc-dropdown-item>
      <hcc-grouped-item :groups="groups" v-else />
    </hcc-dropdown>
    <hcc-dropdown custom customClass="head__labels-list">
      <template #button>
        <hcc-button-icon>
          <div class="head__icons-container">
            <label-icon class="head__icons-icon" />
            <span class="head__icons-label">{{
              $t("configuration.tabs.labels")
            }}</span>
          </div>
        </hcc-button-icon>
      </template>
      <hcc-dropdown-item v-if="companyLabels.length === 0" unclickable>
        <div class="head__labels-empty">
          {{ $t("configuration.labels.empty-labels") }}
        </div>
      </hcc-dropdown-item>

      <hcc-dropdown-item
        v-else
        v-for="(label, $index) in companyLabels"
        :key="$index"
        unclickable
      >
        <div class="head__labels">
          <div class="head__labels-key">
            {{ label.key }}
          </div>
          <div
            class="head__labels-value"
            :class="{ 'value-sm': label.value.length <= 20 }"
          >
            <div>{{ label.value }}</div>
          </div>
        </div>
      </hcc-dropdown-item>
    </hcc-dropdown>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import HccButtonIcon from '@/components/shared/HccButtonIcon/index.vue';
import HccDropdown from '@/components/shared/HccDropdown/index.vue';
import HccDropdownItem from '@/components/shared/HccDropdown/DropdownItem.vue';
import HccGroupedItem from '@/components/shared/HccDropdown/GroupedItem.vue';

export default {
  components: {
    HccButtonIcon,
    HccDropdown,
    HccDropdownItem,
    HccGroupedItem,
  },
  props: {
    labels: {
      type: Array,
      default: () => [],
    },
    tags: {
      type: Array,
      default: () => [],
    },
    isFavorite: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    ...mapGetters({
      userInfo: 'shared/userInfo',
    }),
    companyLabels() {
      if (this.userInfo) {
        if (this.userInfo.company) {
          return this.labels.filter(({ company }) => this.userInfo.company === company);
        }

        return this.labels;
      }
      return [];
    },
    groups() {
      const category = this.tags.find(({ typeTag }) => typeTag === 'Category');
      const tags = [];
      if (category) {
        tags.push({
          name: this.$t('configuration.categories.header'),
          subgroups: [category],
        });
      }
      const subCategory = this.tags.find(({ typeTag }) => {
        if (typeTag) {
          return typeTag.toLowerCase() === 'subcategory';
        }
        return false;
      });
      if (subCategory) {
        tags.push({
          name: this.$t('configuration.categories.sub-category'),
          subgroups: [subCategory],
        });
      }

      return tags;
    },
  },

};
</script>

<style scoped lang="scss">
@import "~styles/components/chat/_chat_header.scss";
</style>
