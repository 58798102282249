<template>
  <div>
    <div v-for="(group, index) in groups" :key="group.name">
      <div class="dropdown__group-title" v-if="!shortenName">
        {{ group.name }}
      </div>
      <div
        v-else
        class="dropdown__group-title"
        v-tooltip="{ content: group.name, visible: !shortenName }"
      >
        {{ truncateWithDots(group.name, 20) }}
      </div>

      <hcc-dropdown-item
        v-for="(subgroup, i) in group.subgroups"
        :border="hasBorder(index, i, group.subgroups)"
        :unclickable="!subgroup.onClick"
        :key="subgroup.name"
        v-on="subgroup.onClick ? { click: subgroup.onClick } : {}"
      >
        <span v-if="!shortenName">
          {{ subgroup.name }}
        </span>
        <span v-else v-tooltip="subgroup.name">
          {{ truncateWithDots(subgroup.name, 35) }}
        </span>
      </hcc-dropdown-item>
    </div>
  </div>
</template>

<script>
import HccDropdownItem from './DropdownItem.vue';

export default {
  components: {
    HccDropdownItem,
  },
  props: {
    groups: {
      type: Array,
      required: true,
    },
  },
  methods: {
    hasBorder(groupIndex, subgroupIndex, subgroups) {
      return subgroupIndex === subgroups.length - 1 && groupIndex !== this.groups.length - 1;
    },
    shortenName(name) {
      return name.length >= 25;
    },
  },
};
</script>

<style scoped lang="scss">
@import "~styles/shared/dropdown.scss";
</style>
