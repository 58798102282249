<template>
  <div class="head">
    <div class="head__user">
      <div class="head__status">
        <span class="head__text">
          {{ clientName }}
          <span
            v-if="!isName"
            class="head__text--faded"
            style="margin-left: 5px"
          >
            {{ clientUsername }}
          </span>
        </span>
        <hcc-badge :text="statusKey" color="alert" fill v-if="showBadge" />
      </div>
      <div>
        <span class="head__text--blue">{{ channelName }}</span>
        <span class="head__text--faded" v-if="agentName">{{ agentName }}</span>
      </div>
    </div>
    <chat-header-icons
      :labels="labels"
      :tags="tags"
      :is-favorite="isFavorite"
    />
  </div>
</template>

<script>
import HccBadge from '@/components/shared/HccBadge/index.vue';
import ChatHeaderIcons from './ChatHeaderIcons.vue';

export default {
  components: {
    HccBadge,
    ChatHeaderIcons,
  },
  props: {
    channel: {
      required: true,
      type: Object,
    },
    client: {
      required: true,
      type: Object,
    },
    lastMessage: {
      required: true,
      type: Object,
    },
    status: {
      type: Object,
    },
    tags: {
      type: Array,
    },
    agent: {
      type: [Object, null],
    },
    isFavorite: Boolean,
  },
  computed: {
    channelName() {
      return this.channel.name ?? '';
    },
    clientName() {
      return this.client.fullName || this.client.name || this.client.username || '';
    },
    clientUsername() {
      return this.client.username || '';
    },
    showBadge() {
      return this.status && this.status.name === 'Cancelada' && this.lastMessage.statusChange?.to === 5;
    },
    statusKey() {
      return this.status ? this.status.name : '';
    },
    isName() {
      return this.clientName === this.clientUsername;
    },
    labels() {
      return this.client.labels;
    },
    agentName() {
      if (this.agent) {
        return `${this.agent.name} (${this.agent.email})`;
      }
      return null;
    },
  },
};
</script>

<style scoped lang="scss">
@import "~styles/components/chat/_chat_header.scss";
</style>
